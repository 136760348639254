<template>
    <div class="container-fluid">
        <img v-if="loading" :src="'/eurasia/img/logo/loading.gif'" style="width: 100px; height:80px" alt="logo">
        <div v-else>
            <SingleProductDetails v-if="productDetails.product_type === 1 || productDetails.product_type === 3" />
            <GroupProductDetails v-else />
        </div>

    </div>
</template>
<script>
//import axios from '../../axios.js';
import { mapGetters } from "vuex";
import SingleProductDetails from "./SingleProductDetails";
import GroupProductDetails from "./GroupProductDetails";
!function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
}(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js');
// window.fbq('init', '777792696884238');
window.fbq('init', '1996185650807019');
window.fbq('track', 'PageView', { value: 0, currency: 'BDT' });
export default {
    name: "ProductDetails",
    data() {
        return {
            product: {}
        }
    },
    components: {
        SingleProductDetails, GroupProductDetails
    },
    computed: {
        ...mapGetters([
            "productDetails", "loading"
        ]),
    },
    watch: {
        $route() {
            const slug = this.$route.params.slug;
            const type = this.$route.params.type;
            this.$store.dispatch("getProductDetails", slug + "/" + type);
            /*axios.get('https://www.savershall.com/eurasia-backend/api/product/'+slug + "/" + type).then(response => {
                // console.log("Response Details===", response);
                this.$store.dispatch("getProductDetails", slug + "/" + type);
                this.product = response.data.data;
            });*/
            //this.$store.dispatch("getProductDetails", slug + "/" + type);
        }
    },
    mounted() {
        const slug = this.$route.params.slug;
        const type = this.$route.params.type;
        this.$store.dispatch("getProductDetails", slug + "/" + type);
        /*axios.get('https://www.savershall.com/eurasia-backend/api/product/'+slug + "/" + type).then(response => {
            // console.log("Response Details===", response);
            this.product = response.data.data;
        });*/
    },
};
</script>